.App {
  width: 100vw;
  height: 100vh; /* Fallback */
  height: calc(var(--vh, 1vh) * 100);
  background-color: black;
  /* background-image: url('./Background.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.logo {
  width: 20%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 80%;
    height: auto;
  }
}

.email-link {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

.email-link:hover {
  text-decoration: underline;
}
